import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ChevronRight from '@mui/icons-material/ChevronRight';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { withStyles } from '@mui/styles';
import LoginImage from "assets/img/gf-spark-illustration.svg";
import GooglePlay from "assets/img/googlePlay.svg";
import AppStore from "assets/img/appStore.svg";
import logo from "assets/img/randstadHireLogo.png";
import Button from "components/Button/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as LoginActions, selectors as LoginSelectors } from "store/modules/login";
import LoginStyles from "./styles";
import { MenuItem, Select, Typography } from "@mui/material";
import i18n from "i18n";
import { withTranslation } from "react-i18next";
import { Translate } from "@mui/icons-material";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = { time: {}, seconds: 30 };
    this.timer = 0;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.round(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
    return obj;
  }

  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
  }

  startTimer() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState({ seconds: 30 })
    setTimeout(() => {
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }, 100)

  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }

  }

  handleEmailChange = event => {
    this.props.onEmailChange(event.target.value);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.onOTPChange('');
      this.startTimer();
      this.props.login();
    }
  };
  handleSubmitOTP = event => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.submitOTP();
    }
  };

  handleOTPChange = event => {
    if (event.target.value.length <= 6 && (/^[0-9]+$/.test(event.target.value) || event.target.value === '')) {
      this.props.onOTPChange(event.target.value);
    }
  };

  resendCode = (event) => {
    event.preventDefault();
    if (this.props.isValidLoginData) {
      this.props.login(true);
      this.startTimer()
    }
  }


  render() {
    const {
      classes,
      email,
      otp,
      onEmailChange,
      isUsernameVerify,
      submitOTP,
      isValidLoginData,
      isLoginLoading,
      isOtpLoading,
      t
    } = this.props;

    return (
      <Grid container className={classes.pageContainer}>
        <Grid
          container
          item
          md={6}
          justifyContent="center"
          alignContent="center"
          className={classes.loginContainer}>
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            item
            md={12}
            className={classes.loginContainer}>
            <div>
              {!isUsernameVerify && (
                <form onSubmit={this.handleSubmit}>
                  <Grid item className={classes.logo}>
                    <a href="https://randstad.ca" alt="Randstad">
                      <img alt="Randstad Logo" src={logo} />
                    </a>
                  </Grid>
                  <Grid item md={12} className={classes.loginItemContainer}>
                    <TextField
                      fullWidth
                      id="email"
                      InputLabelProps={{
                        style: { color: "#929FB0" },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputStyle,
                        },
                      }}
                      placeholder={t("page_login_field_phoneOremail")}
                      value={email}
                      margin="normal"
                      size="small"
                      autoFocus
                      onChange={this.handleEmailChange}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          return this.handleSubmit(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={12} className={classes.buttonContainer}>

                    <Button
                      label={t("common_labels_continue")}
                      icon={<ChevronRight />}
                      alignIcon="right"
                      size="medium"
                      type="submit"
                      isLoading={isLoginLoading}
                      onClick={this.handleSubmit}
                      disabled={!isValidLoginData}
                      className={classes.nextButton}
                    />
                    <div className={classes.resendSection}>
                    </div>
                  </Grid>
                </form>
              )}
              {isUsernameVerify && (
                <form onSubmit={this.handleSubmitOTP}>
                  <Grid item className={classes.logo}>
                    <img alt="Randstad Logo" src={logo} />
                  </Grid>
                  <Grid item md={12} className={classes.loginItemContainer}>
                    <TextField
                      fullWidth
                      id="otp"
                      InputLabelProps={{
                        style: { color: "#929FB0" },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.inputStyle,
                        },
                      }}
                      placeholder={t("page_login_field_code", { digits: 6 })}
                      value={otp}
                      margin="normal"
                      size="small"
                      autoFocus
                      onChange={this.handleOTPChange}
                      onKeyPress={event => {
                        if (event.key === "Enter") {
                          return this.handleSubmitOTP(event);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={12} className={classes.buttonContainer}>
                    <Button
                      label={t("common_labels_next")}
                      icon={<ChevronRight />}
                      alignIcon="right"
                      size="medium"
                      type="submit"
                      onClick={submitOTP}
                      isLoading={isOtpLoading}
                      disabled={otp.length < 6}
                      className={classes.nextButton}
                    />
                    <div className={classes.resendSection}>
                      {this.state.time.s > 0 && <div className="resend-disable">{t("page_login_resendCodeIn").toUpperCase()}</div>}
                      {this.state.time.s > 0 && <div className="minutes">{this.state.time.m > 9 ? this.state.time.m : '0' + this.state.time.m} : {this.state.time.s > 9 ? this.state.time.s : '0' + this.state.time.s}</div>}
                      {this.state.time.s === 0 && <div className="link" onClick={this.resendCode}>{t("page_login_resendCode")}</div>}
                    </div>

                  </Grid>
                </form>
              )}
              <div className={classes.footerSection}>
                <div className={classes.footerTitle}>{t("page_login_noAccount")}</div>
                <div className={classes.footerSubtitle}>{t("page_login_no_account_desc")}</div>
                <div className={classes.footerLink}>
                  <a href="https://play.google.com/store/apps/details?id=com.randstadservices.randstaddirect.hire" target="_blank" rel="noreferrer">
                    <img className={classes.GoogleLink} alt="Google Play Store" src={GooglePlay} />
                  </a>
                  <a href="https://apps.apple.com/ca/app/randstad-direct-hire/id6447973498" target="_blank" rel="noreferrer">
                    <img className={classes.AppleLink} alt="Apple App Store" src={AppStore} />
                  </a>
                </div>
              </div>
            </div>
          </Grid>

        </Grid>

        <Grid item lg={6} className={classes.imageContainer}>
          <img alt="Randstad Login" src={LoginImage} />
        </Grid>

        <Dialog
          open={this.state.showPasswordReset}
          title={t("page_login_dialog_reset_title")}
          description={t("page_login_dialog_reset_desc")}
          confirmText={t("page_login_dialog_reset_confirm")}
          disableBackdropClick={false}
          onClose={this.hidePasswordReset}
          onConfirm={this.handlePasswordReset}
          onCancel={this.hidePasswordReset}
          alertType="form">
          <TextField
            fullWidth
            value={email}
            onChange={event => onEmailChange(event.target.value)}
          />
        </Dialog>
        <div style={{ position: "fixed", right: 20, top: 20 }}>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className={classes.select}
            size={"small"}
            onChange={({ target: { value } }) => {
              i18n.changeLanguage(value);
              window.location.reload()
            }}
            renderValue={(selected) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Translate style={{ marginRight: 8 }} />
                <Typography style={{ textTransform: "capitalize" }}>
                  {t("languages_" + selected)}
                </Typography>
              </div>
            )}
            value={i18n.language}
          >
            <MenuItem style={{ textTransform: "capitalize" }} value={"en"}>{this.props.t("languages_en")}</MenuItem>
            <MenuItem style={{ textTransform: "capitalize" }} value={"fr"}>{this.props.t("languages_fr")}</MenuItem>
          </Select>
        </div>
      </Grid>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  isValidLoginData: PropTypes.bool.isRequired,
  rememberUser: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

const mapState = state => {
  const { rememberUser, email, otp } = state.login;
  return {
    isValidLoginData: LoginSelectors.getisValidLoginData(state),
    isUsernameVerify: LoginSelectors.getIsUsernameVerify(state),
    rememberUser,
    email,
    otp,
    isLoginLoading: LoginSelectors.getIsLoginLoading(state),
    isOtpLoading: LoginSelectors.getIsOtpLoading(state),
  };
};

const mapActions = {
  onEmailChange: LoginActions.setEmail,
  onOTPChange: LoginActions.setOTP,
  login: LoginActions.login,
  submitOTP: LoginActions.submitOTP,
  forgetUser: LoginActions.forgetUser,
};

export default connect(mapState, mapActions)(withStyles(LoginStyles)(withTranslation()(LoginPage)));
