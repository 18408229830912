import {gql} from "@apollo/client";
export default gql`
  query jobTemplate($employerId: ID!){
    jobTemplate(employerId: $employerId) {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      country
      postalCode
      latitude
      longitude
      locationInstruction
      unit
      floor
      postalCode
      description
      isActive
      skills
      gears
      jobTemplateGear {
        gear {
          isActive
          id
          gearName
          frenchGearName
          createdAt
        }
        gearId
        id
        createdAt
      }
      jobTemplateSkill {
        skill {
          frenchSkillName
          id
          isActive
          skillName
          createdAt
        }
        id
        skillId
        createdAt
      }
      isPrivate
      tags
      breakMins
      isBreakPaid
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
        frenchName
      }
    }
  }
`;