import { gql } from "@apollo/client";

export default gql`
  query GetUniquePositions($jobId: Int!) {
    getUniquePositions(jobId: $jobId) {
        id
        isEditable
        isPayable
        isDeletable
        checkedInBy
        checkedOutBy
        addedBy
        payrollStatus
        worker {
            firstName
            lastName
            fullName
            signedProfileImageUrl
            vaccinationStatus
            city
            region
            phone
            email
            tags
          }
        payroll {
          id
          createdAt
          payrollTypeId
          positionId
          taxRegionId
          employmentStandardsRegionId
          rate
          checkIn
          checkOut
          breakDeductionMins
          bonus
          totalUnits
          payableUnits
          payrollType {
            id
            name
            code
          }
          payments {
            paymentStatus {
              id
              code
              description
            }
            id
            createdAt
            sentAt
            responseAt
            workerId
            payrollId
            positionId
            retryPaymentId
            paymentStatusId
            # dcTransferId
            paymentProviderReferenceCode
            email
            securityQuestion
            securityAnswer
            # amount
            errorCode
            errorDescription
            notes
          }
          earnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          wageEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          additionalEarnings {
            id
            payrollId
            payrollEarningsTypeId
            rate
            units
            amount
          }
          employeeDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          employerDeductions {
            id
            payrollId
            amount
            payrollDeductionsTypeId
          }
          totalEmployeeDeductions
          totalEmployerDeductions
          grossWages
          grossEarnings
          netEarnings
        }
        createdAt
        billingRate
        payRate
        createdAtUtc
        employerRating
        workerRating
        startShift
        endShift
        startShiftUtc
        endShiftUtc
        workerId
        defaultWorkerRating
        breakMins
        durationMins
        bonus
        confirmedAt
        confirmedAtUtc
        isReconfirmed
        isConfirmed
        reconfirmedAt
        reconfirmedAtUtc
      }
    }
`;
