import { gql } from "@apollo/client";

export default gql`
mutation UpdateTimeBasePremium($data: EditPayPremiumByTimeInput!) {
    updateTimeBasePremium(data: $data) {
      id
      employerId
      startTime
      endTime
      isForFullShift
      type
      value
      days
      createdAt
      updatedAt
      deletedAt
    }
  }
`;