import * as EmailValidator from "email-validator";
import i18n from "i18n";

const passwordPolicy = /^(?=.*[!@#$%^&*+-?])[a-zA-Z0-9!@#$%^&*+-?]{8,30}$/;
//eslint-disable-next-line
const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/im;

export const mobileValidator = num => {
  if (!num) {
    return false;
  }
  if (!phoneRegex.test(num)) {
    return false;
  }
  return true;
};
export const formatPhone = (input = "") => {
  const phone = (input || "").replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  return !phone[2] ? phone[1] : `(${phone[1]}) ${phone[2]}${phone[3] ? `-${phone[3]}` : ""}`;
};

export const formatInitials = (firstName = "", lastName = "", seperator = ".") => {
  return (firstName.charAt(0) + seperator + lastName.charAt(0)).toUpperCase();
};

export const formatMoney = (number, percision = 2, decimal = ".", thousandsDelimiter = ",") => {
  const sign = number < 0 ? "-" : "";
  const numString = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(percision)), 10),
  );
  const parts = numString.length > 3 ? numString.length % 3 : 0;
  let decimalPart = "";
  if (percision) {
    decimalPart = `${decimal}${Math.abs(number - numString)
      .toFixed(percision)
      .slice(2)}`;
  }

  return `$${sign}${parts ? numString.substr(0, parts) + thousandsDelimiter : ""}${numString
    .substr(parts)
    .replace(/(\d{3})(?=\d)/g, "$1" + thousandsDelimiter)}${decimalPart}`;
};

export const verifyUrl = value => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

function luhn(sin) {
  const sinLength = sin.length;
  let odd = !(sinLength % 2);
  let sum = 0;

  for (let i = 0; i < sinLength; ++i) {
    const n = parseInt(sin.charAt(i), 10);
    odd = !odd;

    if (odd) {
      sum += n;
    } else {
      const x = 2 * n;
      sum += x > 9 ? x - 9 : x;
    }
  }

  return sum % 10 === 0;
}

function onlyNumbers(sin) {
  return sin.replace(/\D/g, "");
}

export const formatNumber = num => {
  if (num) {
    return Math.round(num * 100) / 100;
  }
  return;
};

export const validateSIN = rawSin => {
  if (!rawSin) {
    return false;
  }

  const sin = onlyNumbers(rawSin);
  if (sin.length === 9) {
    if (sin.charAt(0) === "0" || sin.charAt(0) === "8") {
      return false;
    } else {
      return luhn(sin);
    }
  } else {
    return false;
  }
};

export const verifyEmail = email => EmailValidator.validate(email);

export const verifyPassword = password => passwordPolicy.test(password);

export const pluralize = (singularWord, count) => {
  if (count > 1 || count === 0) {
    return `${singularWord}s`;
  }

  return singularWord;
};

export const wasWere = count => {
  if (count > 1 || count === 0) {
    return "were";
  }

  return "was";
};

export const isAre = count => {
  if (count > 1 || count === 0) {
    return "are";
  }

  return "is";
};

export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i18n.t("ordinal_suffix_of_st", { number: i });
  }
  if (j == 2 && k != 12) {
    return i18n.t("ordinal_suffix_of_nd", { number: i });
  }
  if (j == 3 && k != 13) {
    return i18n.t("ordinal_suffix_of_rd", { number: i });
  }
  return i18n.t("ordinal_suffix_of_th", { number: i });
}

export function debounce(a, b = 500, c) { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }


export const postalCodeValidator = (code) => {
  const postalCodePattern = /^[A-Za-z]\d[A-Za-z]\d[A-Za-z]\d$/; // , threeCharacterPattern = /^[A-Za-z]\d[A-Za-z]$/;
  return code.match(postalCodePattern); //|| code.match(threeCharacterPattern);
};