import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import iconDateTime from "assets/img/iconDateTime.svg";
import iconFloppyDisk from "assets/img/iconFloppyDisk.svg";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import { isEmpty } from "lodash";
import moment from "moment";
import Button from "components/Button/Button";
import { InfoRounded } from "@mui/icons-material";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, formattedTimeWithOffset, SplitTime } from "utils/time";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";
import PostJobDialog from "./PostJobDialog";
import Dialog from "components/Dialog/Dialog";
import FeesDialog from "./FeesDialog";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";
import { weekDaysList } from "utils/constant";
import i18n from "i18n";

function Review({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isTemplateSaved, setIsTemplateSaved] = useState(false);
  const canTemplateSelected = useSelector(PostJobSelectors.getCanTemplateSelected);
  const address = useSelector(PostJobSelectors.getAddress);
  const calculatedBill = useSelector(PostJobSelectors.getCalculatedJobBill);
  const {
    workType,
    requiredGear,
    tags,
    skills,
    description,
    date,
    startTime,
    endTime,
    noOfWorkers,
    breakType,
    siteManager,
    checkInManager,
    checkOutManager,
    announcement,
    isRecurring,
    recurringType,
    repeatEvery,
    weekDays,
    monthDate,
    recurringEndDate,
    duration,
    breakDuration,
    timezone,
    templateName,
    locationInstruction,
    unit,
    floor,
    isFixedTerm,
    region
  } = useSelector(PostJobSelectors.getPostJobFormData);
  const [feesDialog, setFeesDialog] = useState(false);
  const isPostJobDialog = useSelector(PostJobSelectors.getIsPostJobDialog);
  const isPostJobLoading = useSelector(PostJobSelectors.getIsPostJobLoading);
  const RegularPayHours = (SplitTime(calculatedBill.regularPayHours));
  const OvertimePayHours = (SplitTime(calculatedBill.overtimePayHours));
  const DoublePayHours = (SplitTime(calculatedBill.doublePayHours));
  const timeBasePremiumHours = (SplitTime(calculatedBill.timeBasePremiumHours));
  const hours = `${duration.hours}`;
  const minutes = `${duration.minutes}`;
  const selectedManagers = !isEmpty(checkInManager) || !isEmpty(siteManager) || !isEmpty(checkOutManager);
  const [openConfirmJob, setOpenConfirmJob] = useState(false);
  const { t } = useTranslation();
  const [isFixedTermJob, setIsFixedTermJob] = useState(false);
  const isFrenchLanguage = i18n.language === "fr";


  useEffect(() => {
    dispatch(
      PostJobActions.calculateEmployerBill({
        breakMins: +breakDuration,
        peopleNeeded: Number(noOfWorkers),
        jobStartTime: startTime,
        jobEndTime: endTime,
        isBreakPaid: breakType === "paid",
        region,
        workTypeId: +workType?.value,
        timezone
      }),
    );
    setIsFixedTermJob(isFixedTerm);
  }, []);

  const hoursCounting = () => {
    let doubleTimePay;
    let overTimePay;
    if (hours >= 10 && hours <= 12) {
      overTimePay = `${hours - 8}`
      return (
        <div dangerouslySetInnerHTML={{ __html: t("component_overtime_dialog_pay1", { overTimePay, minutes }) }} />
      )
    }
    else if (hours > 12) {
      doubleTimePay = `${hours - 12}`
      overTimePay = `${hours - 8 - doubleTimePay}`
      return (
        <div dangerouslySetInnerHTML={{ __html: t("component_overtime_dialog_pay2", { overTimePay, doubleTimePay, minutes }) }} />
      )
    }
  };

  let countedHours = hoursCounting();

  // To open the model box
  const handleClickOpen = () => {
    // if (hours >= 10 && hours <= 12) {
    //   setOpenConfirmJob(true);
    // }
    // else if (hours > 12) {
    //   setOpenConfirmJob(true);
    // }
    // else {
      dispatch(PostJobActions.postJob())
    // }
  }

  const handleClose = () => {
    setOpenConfirmJob(false);
  };

  const confirmJob = () => {
    setOpenConfirmJob(false);
    dispatch(PostJobActions.postJob())
  };

  const postJobConfirm = () => {
    dispatch(PostJobActions.setIsPostJobDialog(false));
    history.push(`/jobs/${isFixedTermJob ? "multi-shift" : "gig"}/overview`);
  }

  const saveTemplate = () => {
    dispatch(PostJobActions.saveTemplate()).then((data) => {
      if (data) {
        setIsTemplateSaved(true)
      }
    });
  };
  const handleChangeTemplateName = e => {
    const name = e.target.value.length === 1 ? e.target.value.trim() : e.target.value
    if (!name.replace(/^[0-9a-zA-Z- ]+$/, '')) {
      dispatch(PostJobActions.setTemplateName(name));
    }
  };

  const isBreakRequired = () => {
    if (duration && duration.hours) {
      const hours = (duration.hours * 60) + duration.minutes;
      const durationHr = Math.round((hours / 60) * 100) / 100
      if (durationHr > 5) {
        return true;
      }
    }
    return false;
  }

  const handleClickOpenFeesDialog = () => {
    setFeesDialog(true)
  }

  const handleClickCloseFeesDialog = () => {
    setFeesDialog(false)
  }

  return (
    <>
      <h3>{t("page_post_job_review_title")}</h3>
      <Divider />
      <div className={classes.workType}>{workType?.[isFrenchLanguage ? "frenchName" : "label"]}</div>

      <div className={classes.location}>
        <div className="icon">
          <img src={iconPinLocation} />
        </div>
        <div className="address">
          <div>{address && address.label}</div>
          <div>
            {unit && (
              <span>
                <b>{t("common_labels_unit")}: </b> {unit}{" "}
              </span>
            )}
            {floor && (
              <span>
                <b>{t("common_labels_floor")}: </b> {floor}{" "}
              </span>
            )}
          </div>
          {locationInstruction && (
            <div>
              <b>{t("common_labels_additional_instruction")}:</b> {locationInstruction}
            </div>
          )}
        </div>
      </div>
      <div className={classes.dateTime}>
        <div className="icon">
          <img src={iconDateTime} />
        </div>
        <div className="detail">
          <div className="date">
            {convertUTCToTimezoneWithOffset(date, timezone, "dddd, MMMM Do, YYYY")}
          </div>
          <div className="time">{formattedTimeWithOffset(startTime, endTime, timezone)}</div>
          <div className="duration">
            ({t("common_labels_job_duration", { hours: duration.hours, minutes: duration.minutes })}){" "}
          </div>
          <div className="button">
            {isBreakRequired() && (
              <div className="break">
                {" "}
                {hours < 5 ?
                  breakType === "unpaid" ? t("common_labels_unpaid_break") : '' :
                  breakType === "paid" ? t("common_labels_paid_break_duration", { breakDuration }) : t("common_labels_unpaid_break_duration", { breakDuration })
                }
              </div>
            )}
            <div className="recurring">{isRecurring === "false" ? t("common_labels_onTime") : isFixedTerm ? t("common_labels_fixed_term_job") : t("common_labels_recurring")}</div>
          </div>
          {isRecurring === "true" && (
            <React.Fragment>
              {recurringType === "Daily" && <div className="time">{t("common_labels_repeat_every_day", { day: repeatEvery })}</div>}
              {recurringType === "Weekly" && (
                <div className="time">
                  {t("common_labels_repeat_every_week", { week: repeatEvery > 1 ? repeatEvery + " " : "" })},
                  &nbsp;
                  <b>{t("common_labels_days")}:</b> &nbsp;
                  {weekDaysList.map((w, ind) => {
                    const day = weekDays.find(d => d.value === w.value);
                    if (day) return <span key={ind} >{day.label + (ind != weekDaysList.length - 1 ? ", " : "")}</span>
                    return null;
                  })}
                </div>
              )}
              {recurringType === "Monthly" && (
                <div className="time">
                  {t("common_labels_repeat_every_month", { month: repeatEvery > 1 ? repeatEvery + " " : "" })},
                  &nbsp;
                  <b>{t("common_labels_date")}:</b> &nbsp;
                  {monthDate.sort((a, b) => a - b).map((m, ind) => (
                    <span key={ind}>{(ind != 0 ? ", " : "") + m}</span>
                  ))}
                </div>
              )}
              <div className="time">
                <b>{isFixedTerm ? t("common_labels_fixed_term_end") : t("common_labels_recurring_end")}:</b>{" "}
                {convertUTCToTimezoneWithOffset(recurringEndDate, timezone, "dddd, MMMM Do, YYYY")} {" "}
              </div >
            </React.Fragment >
          )
          }
        </div >
      </div >
      {requiredGear &&
        requiredGear.length > 0 && (
          <React.Fragment>
            <br />
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                {t("common_labels_required_gears").toUpperCase()}
              </Typography>
              {requiredGear &&
                requiredGear.map((n, index) => typeof n !== "string" && <Chip key={index} label={n?.[isFrenchLanguage ? "frenchGearName" : "gearName"]} variant="outlined" />)}
            </div>
            <br />
          </React.Fragment>
        )}
      {
        skills &&
        skills.length > 0 && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                {t("common_labels_suggested_skills").toUpperCase()} {tags && tags.length > 0 && t("common_labels_suggested_skills_optional").toUpperCase()}
              </Typography>
              <ul>{skills && skills.map((n, index) => typeof n !== "string"  && <li key={index}>{n?.[isFrenchLanguage ? "frenchSkillName" : "skillName"]}</li>)}</ul>
              {tags && tags.length > 0 && (
                <div style={{ marginBottom: "20px", paddingLeft: "20px" }}>
                  {tags.map((n, index) => (
                    <Chip key={index} label={n} variant="outlined" />
                  ))}
                </div>
              )}
            </div>
          </React.Fragment>
        )
      }
      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          {t("common_labels_description").toUpperCase()}
        </Typography>
        <ul>
          <li style={{ wordBreak: "break-word" }}>{description}</li>
        </ul>
      </div>
      {
        selectedManagers && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                {t("common_labels_project_role")}
              </Typography>
              <Grid container spacing={2} justifyContent={"flex-start"}>
                {!isEmpty(siteManager) && <Grid item md={4} sm={6} xs={12}>
                  <Card>
                    <CardHeader
                      avatar={<Avatar alt={siteManager.manager?.firstName} src={siteManager.manager?.signedProfileImageUrl} />}
                      title={
                        `${siteManager.manager.firstName} ${siteManager.manager.lastName}`
                      }
                      subheader={t("common_labels_managers_site")}
                    />
                  </Card>
                </Grid>}
                {!isEmpty(checkInManager) && <Grid item md={4} sm={6} xs={12}>
                  <Card>
                    <CardHeader
                      avatar={<Avatar alt={checkInManager.manager?.firstName} src={checkInManager.manager?.signedProfileImageUrl} />}
                      title={
                        `${checkInManager.manager.firstName} ${checkInManager.manager.lastName}`
                      }
                      subheader={t("common_labels_managers_checkIn")}
                    />
                  </Card>
                </Grid>}
                {!isEmpty(checkOutManager) && <Grid item md={4} sm={6} xs={12}>
                  <Card>
                    <CardHeader
                      avatar={<Avatar alt={checkOutManager.manager?.firstName} src={checkOutManager.manager?.signedProfileImageUrl} />}
                      title={
                        `${checkOutManager.manager.firstName} ${checkOutManager.manager.lastName}`
                      }
                      subheader={t("common_labels_managers_checkOut")}
                    />
                  </Card>
                </Grid>}
              </Grid>
            </div>
            <br />
          </React.Fragment>
        )
      }

      {
        announcement.length &&
        announcement[0].title && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                {t("page_communication_tabs_announcement")} ({announcement.length})
              </Typography>
              <Grid container spacing={2} justifyContent={"space-around"}>
                <Grid item md={12} sm={12} xs={12}>
                  {announcement.map((n, index) => (
                    <Card key={index} className={classes.announcementReview}>
                      <CardContent>
                        <div className="title">{n.title}</div>
                        <p className="description">{n.description}</p>
                        <div className="recurring">{t(n.repeatType === "Once" ? "common_labels_once" : "common_labels_weekly")}</div>
                        {n.repeatType === "Once" && <div> {t("common_labels_minutes_before", { minutes: n.before })}</div>}
                        {n.repeatType === "Weekly" && (
                          <div>
                            {t("common_labels_time")}: {moment(n.time).format("hh:mm a")}
                            &nbsp; (
                            {n.days.map((m, ind) => (
                              <span key={ind}>{(ind != 0 ? ", " : "") + m.label}</span>
                            ))}
                            )
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </div>
            <br />
          </React.Fragment>
        )
      }

      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          {t("common_labels_budget")}
        </Typography>
        <Grid container spacing={2}>
          {!canTemplateSelected && <Grid item md={6} sm={6} xs={12}>
            <Card>
              <CardHeader
                avatar={<img src={iconFloppyDisk} width={30} />}
                className={classes.templateTitle}
                title={t("page_post_job_review_save_as_template")}
              />
              <div className={classes.template}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  {t("page_post_job_review_save_as_template_desc")}
                </Typography>
                <div className={classes.templateForm}>
                  <TextField
                    id="template-name"
                    name="template-name"
                    placeholder={t("page_edit_template_field_template_name")}
                    value={templateName}
                    onChange={handleChangeTemplateName}
                    fullWidth
                    variant="standard"
                    margin="none"
                  />
                  <Button
                    className={classes.continueButton}
                    size="small"
                    disabled={!templateName || isTemplateSaved}
                    onClick={saveTemplate}
                    label={t("common_labels_save")}
                  />
                </div>
              </div>
            </Card>
          </Grid>}
          <Grid item md={canTemplateSelected ? 12 : 6} sm={canTemplateSelected ? 12 : 6} xs={12} justifyContent={"start"}>
            <Grid container xs={12} sm={12}>
              <Grid item xs={6} sm={10}>
                {t("common_labels_hourlyPay", { hours: RegularPayHours.Hours, minutes: RegularPayHours.Minutes, workers: noOfWorkers })}
              </Grid>
              <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.hourlyPay)}
              </Grid>
            </Grid>
            {calculatedBill.overtimePay > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  {t("common_labels_otPay", { hours: OvertimePayHours.Hours, minutes: OvertimePayHours.Minutes, workers: noOfWorkers })}
                  {/* <Tooltip title={t("common_labels_overtime_text", { overTimeRate: calculatedBill.dailyOTRate })}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip> */}
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculatedBill.overtimePay)}
                </Grid>
              </Grid>
            )}
            {calculatedBill.doublePay > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  {t("common_labels_dtPay", { hours: DoublePayHours.Hours, minutes: DoublePayHours.Minutes, workers: noOfWorkers })}
                  {/* <Tooltip title={t("common_labels_doubletime_text", { overTimeRate: calculatedBill.dailyOTRate, doubleTimeRate: calculatedBill.dailyDTRate })}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip> */}
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculatedBill.doublePay)}
                </Grid>
              </Grid>
            )}
            {calculatedBill.timeBasePremium > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  {t("common_labels_tbpPay", { hours: timeBasePremiumHours.Hours, minutes: timeBasePremiumHours.Minutes, workers: noOfWorkers })}
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculatedBill.timeBasePremium)}
                </Grid>
              </Grid>
            )}
            <Grid container xs={12} sm={12}>
              <Grid item xs={6} sm={10}>
                {t("common_labels_gf_fees")}
                <IconButton
                  style={{ padding: "5px", marginTop: "-3px" }}
                  onClick={handleClickOpenFeesDialog}>
                  <InfoRounded style={{ fontSize: "14px" }} />
                </IconButton>
              </Grid>
              <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.gfFee)}
              </Grid>
            </Grid>
            {calculatedBill?.taxes?.map(tx => (
              <Grid key={tx.label} container xs={12}>
                <Grid item xs={6}>
                  {`${tx.label} ${tx.percentage}%`}
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(tx.amount)}
                </Grid>
              </Grid>
            ))}
            <Divider />
            <Grid
              container
              className={classes.total}
              xs={12}
              sm={12}
            >
              <Grid item xs={6} sm={9}>
                {t("common_labels_totalDue").toUpperCase()}
              </Grid>
              <Grid item xs={6} sm={3} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.total)}
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
      <Button
        className={classes.continueButton}
        size="small"
        variant="outlined"
        onClick={handleClickOpen}
        label={t("common_labels_post_job")}
        isLoading={isPostJobLoading}
      />
      {
        openConfirmJob &&
        <PostJobDialog
          handleClose={handleClose}
          Time={countedHours}
          openPostJob={openConfirmJob}
          confirmJob={confirmJob}
        />
      }
      <Dialog
        open={isPostJobDialog}
        onConfirm={postJobConfirm}
        confirmText={t("common_labels_ok")}
        alertType="success"
        title={t("page_post_job_review_dialog_job_created")}
      >
      </Dialog>
      <FeesDialog
        feesDialog={feesDialog}
        handleClickCloseFeesDialog={handleClickCloseFeesDialog}
      />
    </>
  );
}

export default Review;