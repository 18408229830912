import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import { fetchProfile } from "../profile/actions";
import removeTemplateMutation from "./graphql/mutations/remove-template";
import duplicateJobTemplateMutation from "./graphql/mutations/duplicate-job-template";
import updateJobTemplateMutation from "./graphql/mutations/update-job-template";
import updateNotificationMutation from "./graphql/mutations/update-notification";
import updateTimezoneMutation from "./graphql/mutations/update-timezone";
import GetCompanyJobTemplateQuery from "./graphql/queries/get-company-job-template";
import GetJobTemplateByIdQuery from "./graphql/queries/get-job-template-by-id";
import GetMyJobTemplateQuery from "./graphql/queries/get-my-job-template";
import FetchTimezoneQuery from "./graphql/queries/get-timezone";
import { getJobTemplateDetail } from "./selectors";
import types from "./types";
import i18n from "i18n";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsUpdateTimezoneLoading = setLoadingState("updateTimezone");
const setIsUpdateNotificationLoading = setLoadingState("updateNotification");
const setIsUpdateTemplateLoading = setLoadingState("updateTemplate");

export const fetchTimezone = () => (dispatch) => {
  return ApolloClient.query({
    query: FetchTimezoneQuery,
  })
    .then(({ data }) => {
      if(data && data.timezones){
        dispatch({
          type: types.SET_TIMEZONE_LIST_DATA,
          payload: {
            data: data.timezones
          },
        });
      }
    })
    .catch((e) => {
      console.log(e)
    });
};

export const uploadTimezone = (timezone) => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setIsUpdateTimezoneLoading(true))
  return ApolloClient.mutate({
    mutation: updateTimezoneMutation,
    variables: {
      data: {
        employerId: employerId,
        timezone
      },
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast(i18n.t("toast_success_setting_timezone_update")));
      dispatch(setIsUpdateTimezoneLoading(false))
      dispatch(fetchProfile())
    })
    .catch(e => {
      dispatch(setIsUpdateTimezoneLoading(false))
      dispatch(MetaActions.errorToast(i18n.t("toast_error_setting_timezone_update",{error:e})));
    });
};

export const uploadNotification = () => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setIsUpdateNotificationLoading(true))
  return ApolloClient.mutate({
    mutation: updateNotificationMutation,
    variables: {
      data: {
        employerId: employerId,
      },
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast(i18n.t("toast_success_setting_notification_update")));
      dispatch(setIsUpdateNotificationLoading(false))
      dispatch(fetchProfile());
    })
    .catch(e => {
      dispatch(setIsUpdateNotificationLoading(false))
      dispatch(MetaActions.errorToast(i18n.t("toast_error_setting_notification_update",{error:e})));
    });
};

export const fetchMyJobTemplate = () => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.query({
    query: GetMyJobTemplateQuery,
    variables: { employerId: employerId }
  })
    .then(({ data }) => {
      if(data && data.myJobTemplate){
        dispatch({
          type: types.SET_MY_JOB_TEMPLATE_LIST,
          payload: {
            data: data.myJobTemplate
          },
        });
      }
    })
    .catch((e) => {
      console.log(e)
    });
};

export const fetchCompanyJobTemplate = () => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.query({
    query: GetCompanyJobTemplateQuery,
    variables: { employerId: employerId }
  })
    .then(({ data }) => {
      if(data && data.jobTemplate){
        dispatch({
          type: types.SET_COMPANY_JOB_TEMPLATE_LIST,
          payload: {
            data: data.jobTemplate
          },
        });
      }
    })
    .catch((e) => {
      console.log(e)
    });
};

export const removeJobTemplate = (id) => (dispatch) => {
  return ApolloClient.mutate({
    mutation: removeTemplateMutation,
    variables: { id }
  })
    .then(({ data }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(fetchMyJobTemplate())
      dispatch(MetaActions.successToast(i18n.t("toast_success_setting_remove_job_template")));
    })
    .catch((e) => {
      console.log(e)
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const setTemplateDetail = data => ({
  type: types.SET_TEMPLATE_DETAIL,
  payload: { data },
});

export const fetchJobTemplateById = (id) => (dispatch) => {
  return ApolloClient.query({
    query: GetJobTemplateByIdQuery,
    variables: { id }
  })
    .then(({ data }) => {
      if(data && data.jobTemplateById){
        dispatch(setTemplateDetail(data.jobTemplateById));
      }
    })
    .catch((e) => {
      console.log(e)
    });
};

export const duplicateTemplate = () => (dispatch, getState) => {
  const templateDetail = getJobTemplateDetail(getState());
  const variable = {
    employerId: templateDetail.employerId,
    country: templateDetail.country,
    templateName: templateDetail.templateName,
    workTypeId: templateDetail.workTypeId,
    peopleNeeded: templateDetail.peopleNeeded,
    street: templateDetail.street,
    city: templateDetail.city,
    region: templateDetail.region,
    latitude: Number(templateDetail.latitude),
    longitude: Number(templateDetail.longitude),
    isBreakPaid: templateDetail.breakType === "paid" ? true : false,
    unit: templateDetail.unit,
    description: templateDetail.description,
    gears: templateDetail.gears?.map?.(a=>a?.id?.toString()),
    skills: templateDetail.skills?.map?.(a=>a?.id?.toString()),
    isPrivate: templateDetail.isPrivate,
    tags: templateDetail.tags,
    floor: templateDetail.floor,
    locationInstruction: templateDetail.locationInstruction,
    breakMins: templateDetail.breakMins,
    postalCode: templateDetail.postalCode?.trim()
  }
  
  dispatch(setIsUpdateTemplateLoading(true))
  return ApolloClient.mutate({
    mutation: duplicateJobTemplateMutation,
    variables: { data: variable }
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(i18n.t("toast_success_setting_duplicate_job_template")));
      dispatch(setIsUpdateTemplateLoading(false))
      return data;
    })
    .catch((e) => {
      dispatch(setIsUpdateTemplateLoading(false))
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const updateTemplate = () => (dispatch, getState) => {
  const templateDetail = getJobTemplateDetail(getState());
  const variable = {
    country: templateDetail.country,
    templateName: templateDetail.templateName,
    id: templateDetail.id,
    workTypeId: templateDetail.workType.value,
    peopleNeeded: templateDetail.noOfWorkers,
    street: templateDetail.street,
    city: templateDetail.city,
    region: templateDetail.region,
    latitude: Number(templateDetail.latitude),
    longitude: Number(templateDetail.longitude),
    isBreakPaid: templateDetail.breakType === "paid" ? true : false,
    unit: templateDetail.unit,
    description: templateDetail.description,
    gears: templateDetail.gears?.map?.(a=>a?.id?.toString()),
    skills: templateDetail.skills?.map?.(a=>a?.id?.toString()),
    isPrivate: templateDetail.isPrivate,
    tags: templateDetail.tags,
    floor: templateDetail.floor,
    locationInstruction: templateDetail.locationInstruction,
    postalCode: templateDetail.postalCode,
  }
  
  dispatch(setIsUpdateTemplateLoading(true))
  return ApolloClient.mutate({
    mutation: updateJobTemplateMutation,
    variables: { data: variable }
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(i18n.t("toast_success_setting_update_job_template")));
      dispatch(setIsUpdateTemplateLoading(false))
      return data;
    })
    .catch((e) => {
      dispatch(setIsUpdateTemplateLoading(false))
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};