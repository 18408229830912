import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Alarm, AlarmOn, MoreVert, PersonAdd, PersonAddDisabled, RemoveCircle, Report, ReportOff, Stars, WorkOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import noop from "lodash/noop";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import BanDialog from "../Dialogs/BanDialog";
import CheckInDialog from "../Dialogs/CheckInDialog";
import CheckOutDialog from "../Dialogs/CheckOutDialog";
import NoShowDialog from "../Dialogs/NoShowDialog";
import PreferDialog from "../Dialogs/PreferDialog";
// import RatingDialog from "../Dialogs/RatingDialog";
import { useTranslation } from "react-i18next";
import RemoveDialog from "../Dialogs/RemoveDialog";

const useStyles = makeStyles(({ spacing, typography }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
}));

function PositionMenu({ position, isWorkerPickerOpen }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isCheckingIn, setIsCheckingIn] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [isFlaggingNoShow, setIsFlaggingNoShow] = useState(false);
  const [isPreferring, setIsPreferring] = useState(false);
  const [isBanning, setIsBanning] = useState(false);
  // const [isRating, setIsRating] = useState(false);
  const menuId = `action-menu-${position.id}`;
  const { t } = useTranslation();
  const [isRemoving, setIsRemoving] = useState(false);

  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const { currentPage: allWorkersPage } = useSelector(
    JobDetailSelectors.getAllWorkersPaginationData
  );
  const { currentPage: preferredWorkersPage } = useSelector(
    JobDetailSelectors.getPreferredWorkersPaginationData
  );

  const refreshWorkerLists = () => {
    if (isWorkerPickerOpen) {
      // dispatch(JobDetailActions.fetchAllWorkers(allWorkersPage));
      // dispatch(JobDetailActions.fetchPreferredWorkers(preferredWorkersPage));
    }
  };

  const toggleFactory = (fn = noop) => () => {
    fn(v => !v);
    closeMenu();
  };

  const toggleCheckIn = toggleFactory(setIsCheckingIn);
  const toggleCheckOut = toggleFactory(setIsCheckingOut);
  const toggleFlaggingNoShow = toggleFactory(setIsFlaggingNoShow);
  const togglePreferring = toggleFactory(setIsPreferring);
  const toggleBanning = toggleFactory(setIsBanning);
  // const toggleRating = toggleFactory(setIsRating);
  const toggleRemoving = toggleFactory(setIsRemoving);

  const canRemove = useSelector(JobDetailSelectors.getCanRemovePosition(position.id));
  const canCheckIn = useSelector(JobDetailSelectors.getCanCheckInWorker(position.id));
  const canCheckOut = useSelector(JobDetailSelectors.getCanCheckOutWorker(position.id));
  const canFlagNoShow = useSelector(JobDetailSelectors.getCanFlagNoShow(position.id));
  const canPrefer = useSelector(JobDetailSelectors.getCanPreferWorker(position.workerId));
  const canUnprefer = useSelector(JobDetailSelectors.getCanUnpreferWorker(position.workerId));
  const canBan = useSelector(JobDetailSelectors.getCanBanWorker(position.workerId));
  const canUnban = useSelector(JobDetailSelectors.getCanUnbanWorker(position.workerId));
  // const canRate = useSelector(JobDetailSelectors.getCanRateWorker(position.id));
  const canEditCheckIn = useSelector(JobDetailSelectors.getCanEditCheckInWorker(position.id))
  const canEditCheckOut = useSelector(JobDetailSelectors.getCanEditCheckOutWorker(position.id))
  const canShowMenu = canCheckOut || canPrefer || canUnprefer || canBan || canUnban || canFlagNoShow || canRemove;

  const handleCheckout = () => {
    toggleCheckOut();
    dispatch(JobDetailActions.checkoutWorker(position.id));
  };

  const handleNoShow = async (ban = false) => {
    toggleFlaggingNoShow();
    await dispatch(JobDetailActions.noShowWorker(position.workerId, ban));
    refreshWorkerLists();
  };

  const handlePrefer = async () => {
    togglePreferring();
    await dispatch(JobDetailActions.preferWorker(position.workerId));
    refreshWorkerLists();
  };

  const handleBan = async reason => {
    toggleBanning();
    await dispatch(JobDetailActions.banWorker(position.workerId, reason));
    refreshWorkerLists();
  };

  const handleClearException = type => async () => {
    closeMenu();
    await dispatch(JobDetailActions.clearException(position.workerId, type));
    refreshWorkerLists();
  };

  // const handleRating = (rating, workerRatingComment) => {
  //   toggleRating();
  //   dispatch(JobDetailActions.ratingWorker(position.workerId, rating.map(n => ({ rating: n.rating, ratingOptionId: n.ratingOptionId })), workerRatingComment));
  // };

  const handleRemove = async (suspend = false, ban = false, withFuture) => {
    toggleRemoving();
    await dispatch(JobDetailActions.removeWorker(position.workerId, suspend, ban, withFuture));
    refreshWorkerLists();
  };

  return (
    <>
      {canShowMenu && <IconButton aria-controls={menuId} aria-haspopup="true" onClick={openMenu} disableRipple>
        <MoreVert color="primary" />
      </IconButton>}
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
        {canCheckIn && (
          <MenuItem onClick={toggleCheckIn}>
            <Alarm className={classes.icon} color="action" />
            {`${canEditCheckIn ? t("page_job_details_position_edit_check_in") : t("page_job_details_position_check_in")}`}
          </MenuItem>
        )}
        {canCheckOut && (
          <MenuItem onClick={toggleCheckOut}>
            <AlarmOn className={classes.icon} color="action" />
            {`${canEditCheckOut ? t("page_job_details_position_edit_check_out") : t("page_job_details_position_check_out")}`}
          </MenuItem>
        )}
        {/* {canRate && (
          <MenuItem onClick={toggleRating}>
            <Stars className={classes.icon} color="action" />
            {t("page_job_details_position_rate")}
          </MenuItem>
        )} */}
        {canPrefer && (
          <MenuItem onClick={togglePreferring}>
            <PersonAdd className={classes.icon} color="action" />
            {t("page_job_details_position_prefer")}
          </MenuItem>
        )}
        {canUnprefer && (
          <MenuItem onClick={handleClearException("preferred")}>
            <PersonAddDisabled className={classes.icon} color="action" />
            {t("page_job_details_position_remove_prefer")}
          </MenuItem>
        )}
        {canBan && (
          <MenuItem onClick={toggleBanning}>
            <Report className={classes.icon} color="action" />
            {t("page_job_details_position_ban")}
          </MenuItem>
        )}
        {canUnban && (
          <MenuItem onClick={handleClearException("banned")}>
            <ReportOff className={classes.icon} color="action" />
            {t("page_job_details_position_remove_ban")}
          </MenuItem>
        )}
        {canFlagNoShow && (
          <MenuItem onClick={toggleFlaggingNoShow}>
            <WorkOff className={classes.icon} color="action" />
            {t("page_job_details_position_noShow")}
          </MenuItem>
        )}
        {/* {canRemove && (
          <MenuItem onClick={toggleRemoving}>
            <RemoveCircle className={classes.icon} color="action" />
            {t("page_job_details_position_remove")}
          </MenuItem>
        )} */}
      </Menu>

      <CheckInDialog
        open={isCheckingIn}
        onClose={toggleCheckIn}
        onCancel={toggleCheckIn}
        onConfirm={toggleCheckIn}
        position={position}
      />
      <CheckOutDialog
        open={isCheckingOut}
        onClose={toggleCheckOut}
        onCancel={toggleCheckOut}
        onConfirm={handleCheckout}
        position={position}
      />
      <NoShowDialog
        open={isFlaggingNoShow}
        onClose={toggleFlaggingNoShow}
        onCancel={toggleFlaggingNoShow}
        onConfirm={handleNoShow}
        position={position}
      />
      <PreferDialog
        open={isPreferring}
        onClose={togglePreferring}
        onCancel={togglePreferring}
        onConfirm={handlePrefer}
        position={position}
      />
      <BanDialog
        open={isBanning}
        onClose={toggleBanning}
        onCancel={toggleBanning}
        onConfirm={handleBan}
        position={position}
      />
      {/* <RatingDialog
        open={isRating}
        onClose={toggleRating}
        onCancel={toggleRating}
        onConfirm={handleRating}
        position={position}
      /> */}

      <RemoveDialog
        open={isRemoving}
        onClose={toggleRemoving}
        onCancel={toggleRemoving}
        onConfirm={handleRemove}
        position={position}
      />
    </>
  );
}

export default PositionMenu;
