import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Switch,
  MenuItem
} from "@mui/material";
import { isEmpty, remove } from "lodash";
import useStyles from "views/Job/PostJob/components/styles";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import { useTranslation } from "react-i18next";
import { actions as MetaActions } from "store/modules/meta";
import i18n from "i18n";
import { postalCodeValidator } from "utils/stringUtils";

function EditJobTemplate(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workType = useSelector(PostJobSelectors.getWorkTypeList);
  const [gear, setGear] = useState("");
  const [skill, setSkill] = useState("");
  const employerData = useSelector(ProfileSelectors.getEmployerDetail);
  const canPostPrivateJob = employerData.canPostPrivateJob;
  const templateDetail = useSelector(SettingsSelectors.getJobTemplateDetail);
  const address = useSelector(SettingsSelectors.getJobTemplateAddress);
  const isLoading = useSelector(SettingsSelectors.getIsUpdateTemplateLoading);
  const params = new URLSearchParams(props.history.location.search);
  const jobTemplateId = params.get("id");
  const type = params.get("type");
  const [tag, setTag] = useState("");
  const [duplicateTemplateName, setDuplicateTemplateName] = useState('');
  const [validDuplicateTemplateName, setValidDuplicateTemplateName] = useState(type === 'duplicate' ? false : true);
  const { t } = useTranslation();
  const skillList = useSelector(PostJobSelectors.getSkillList);
  const gearList = useSelector(PostJobSelectors.getGearList);
  const [touchedPostalCodeField, setTouchedPostalCodeField] = useState(false);

  const isFrenchLanguage = i18n.language === "fr";
  if (templateDetail && (templateDetail.skills || templateDetail.jobTemplateSkill)) templateDetail.skills = templateDetail.jobTemplateSkill ? templateDetail.jobTemplateSkill.map(a => a.skill || a) : templateDetail.skills.filter(s => typeof s !== "string")
  if (templateDetail && (templateDetail.gears || templateDetail.jobTemplateGear)) templateDetail.gears = templateDetail.jobTemplateGear ? templateDetail.jobTemplateGear.map(a => a.gear || a) : templateDetail.gears.filter(s => typeof s !== "string")

  useEffect(() => {
    dispatch(PostJobActions.fetchWorkTypes());
    dispatch(PostJobActions.fetchGear());
    dispatch(PostJobActions.fetchSkills());
    if (jobTemplateId) {
      dispatch(SettingsActions.fetchJobTemplateById(Number(jobTemplateId)));
    }
    dispatch(ProfileActions.fetchEmployerDetail());
  }, []);

  const handleChangeTemplateName = e => {
    if (e.target.value.length <= 50) {
      setDuplicateTemplateName(e.target.value);
      setValidDuplicateTemplateName(true)
      templateDetail.templateName = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };

  const handleChangePostalCode = (e) => {
    if (e.target.value.length <= 6) {
      templateDetail.postalCode = e.target.value.replace?.(" ", "")?.toUpperCase();
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  }

  const handleChangeUnit = e => {
    if (e.target.value.length <= 10) {
      if (e.target.value.match(/[0-9]/g)) {
        templateDetail.unit = e.target.value;
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      }
      else {
        templateDetail.unit = "";
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      }
    }
  };
  const handleChangeFloor = e => {
    if (e.target.value.length <= 10) {
      templateDetail.floor = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeInstruction = e => {
    if (e.target.value.length <= 100) {
      templateDetail.locationInstruction = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeWorkType = (e, newValue) => {
    templateDetail.workType = newValue;
    templateDetail.workTypeId = newValue.value
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeDescription = e => {
    if (e.target.value.length <= 250) {
      templateDetail.description = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeGear = e => {
    if (e) {
      if (e.trim() && e.length <= 50) {
        setGear(e.trim());
      }
    } else {
      setGear(e);
    }
  };
  const addGear = () => {
    if (templateDetail.gears.findIndex(g => g?.[isFrenchLanguage ? "frenchGearName" : "gearName"] === gear) < 0) {
      const seleted = [...templateDetail.gears];
      const item = gearList.find(s => s?.[isFrenchLanguage ? "frenchGearName" : "gearName"] === gear);
      if (item) {
        seleted.push(item);
        templateDetail.gears = seleted;
        templateDetail.jobTemplateGear = seleted;
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      }
      setGear("");
    } else {
      dispatch(MetaActions.errorToast(t(`common_errors_gear_already_added`)));
    }
  };
  const handleDelete = value => {
    const seleted = [...templateDetail.gears];
    remove(seleted, n => (typeof n === "string" ? n === value : n?.id === value?.id));
    templateDetail.gears = seleted;
    templateDetail.jobTemplateGear = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeTag = (e) => {
    if (e.target.value.length <= 50) {
      setTag(e.target.value);
    }
  }
  const addTag = () => {
    if (templateDetail.tags.indexOf(tag) < 0) {
      const seleted = [...templateDetail.tags];
      seleted.push(tag);
      templateDetail.tags = seleted;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      setTag("");
    }
  };
  const handleDeleteTags = value => {
    const seleted = [...templateDetail.tags];
    remove(seleted, n => n === value);
    templateDetail.tags = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeSkill = e => {
    if (e) {
      if (e.trim() && e.length <= 50) {
        setSkill(e.trim());
      }
    } else {
      setSkill(e);
    }
  };
  const addSkill = () => {
    if (templateDetail.skills.findIndex(s => s?.[isFrenchLanguage ? "frenchSkillName" : "skillName"] === skill) < 0) {
      if (skill.replace(/^\s+|\s+$/gm, '')) {
        const seleted = [...templateDetail.skills];
        const item = skillList.find(s => s?.[isFrenchLanguage ? "frenchSkillName" : "skillName"] === skill);
        if (item) {
          seleted.push(item);
          templateDetail.skills = seleted;
          templateDetail.jobTemplateSkill = seleted;
          dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
        }
        setSkill("");
      }
    } else {
      dispatch(MetaActions.errorToast(t(`common_errors_skill_already_added`)));
    }
  };
  const handleDeleteSkill = value => {
    const seleted = [...templateDetail.skills];
    remove(seleted, n => (typeof n === "string" ? n === value : n?.id === value?.id));
    templateDetail.skills = seleted;
    templateDetail.jobTemplateSkill = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const onIsPrivateChange = (isPrivate) => {
    templateDetail.isPrivate = isPrivate;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    if (isPrivate === false) {
      templateDetail.tags = [];
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const onAddressChange = (selectedLocation, location) => {
    if (location) {
      templateDetail.street = location.street;
      templateDetail.city = location.city;
      templateDetail.region = location.region;
      templateDetail.latitude = location.lat;
      templateDetail.longitude = location.lng;
      templateDetail.country = location.country;
      if (location?.postalCode)
        templateDetail.postalCode = location.postalCode?.replace?.(" ", "")?.toUpperCase();
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    } else {
      templateDetail.street = '';
      templateDetail.city = '';
      templateDetail.region = '';
      templateDetail.latitude = '';
      templateDetail.longitude = '';
      templateDetail.country = '';
      templateDetail.postalCode = '';
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const updateTemplate = () => {
    if (type === 'duplicate') {
      dispatch(SettingsActions.duplicateTemplate())
        .then(data => {
          if (data) {
            props.history.push(`/settings`);
          }
        });
    }
    else {
      dispatch(SettingsActions.updateTemplate())
        .then(data => {
          if (data) {
            props.history.push(`/settings`);
          }
        });
    }
  }

  const isValidTemplate = () => {
    return (
      templateDetail &&
      templateDetail.templateName &&
      templateDetail.workType &&
      templateDetail.description &&
      address &&
      address.label &&
      templateDetail.postalCode && templateDetail.postalCode.length >= 3 && postalCodeValidator(templateDetail.postalCode || "") &&
      validDuplicateTemplateName &&
      templateDetail.gears?.length > 0 &&
      templateDetail.skills?.length > 0 &&
      !(templateDetail.isPrivate && templateDetail.tags?.length == 0)
    );
  }

  const handlePostalCodeBlur = () => {
    setTouchedPostalCodeField(true);
  };

  return (
    <React.Fragment>
      {!isEmpty(templateDetail) && (
        <MainContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h3>{t("page_edit_template_field_template_name")}</h3>
              <div>
                <TextField
                  id="templateName"
                  name="templateName"
                  placeholder={t("page_edit_template_field_template_name")}
                  value={type === 'duplicate' ? duplicateTemplateName : templateDetail.templateName}
                  onChange={handleChangeTemplateName}
                  fullWidth
                  margin="none"
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <h3>{t("page_edit_template_field_work")}</h3>
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={workType}
                  renderOption={(op, state) => {
                    return (
                      <MenuItem {...op}>
                        {state[isFrenchLanguage ? "frenchName" : "label"]}
                      </MenuItem>
                    )
                  }}
                  value={templateDetail && templateDetail.workType && templateDetail.workType[isFrenchLanguage ? "frenchName" : "label"]}
                  // sx={{ width: 300 }}
                  onChange={handleChangeWorkType}
                  fullWidth
                  renderInput={params => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>{t("common_labels_description")}</h3>
              <div className={classes.gearTextBox}>
                <TextField
                  id="description"
                  name="description"
                  // label="Description"
                  placeholder={t("page_post_job_workType_description_placeholder")}
                  value={templateDetail.description}
                  onChange={handleChangeDescription}
                  fullWidth
                  margin="none"
                  multiline
                  minRows={4}
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <h3>{t("common_labels_required_gears")}</h3>
              <div className={classes.gearTextBox}>
                <Autocomplete
                  id="free-solo-gear"
                  freeSolo
                  disableClearable={!gear}
                  onChange={(event, newValue) => {
                    handleChangeGear(newValue)
                  }}
                  value={gear || ""}
                  fullWidth
                  options={gearList.map((option) => option?.[isFrenchLanguage ? "frenchGearName" : "gearName"])}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      value={gear}
                      placeholder={t("common_labels_required_gears_input")}
                      onChange={e => handleChangeGear(e.target.value)}
                      onKeyPress={e => {
                        if (e.key === "Enter" && e.target.value) {
                          addGear();
                        }
                      }}
                    />
                  }
                />

                <Button
                  disabled={!gear}
                  onClick={addGear}
                  label={t("common_labels_add")}
                />
              </div>
              {templateDetail &&
                templateDetail.gears &&
                templateDetail.gears.length > 0 && (
                  <div className={classes.selectedSection}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      {t("common_labels_selected")} ({templateDetail.gears.length})
                    </Typography>
                    {templateDetail.gears.map((n, index) => (
                      <Chip
                        key={index}
                        label={n?.[isFrenchLanguage ? "frenchGearName" : "gearName"] || n?.toString()}
                        variant="outlined"
                        onDelete={() => handleDelete(n)}
                      />
                    ))}
                  </div>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>{t("common_labels_suggested_skills")} {canPostPrivateJob && t('common_labels_suggested_skills_optional')}</h3>

              {canPostPrivateJob && (
                <div style={{ marginBottom: "20px" }}>
                  <div className={classes.tags}>
                    <h6 style={{ marginRight: "3px", marginTop: '12px' }}>{t("page_post_job_required_gears_private_job")}</h6>
                    <Switch
                      id="isPrivate"
                      defaultChecked={templateDetail.isPrivate}
                      onChange={(e) => onIsPrivateChange(e.target.checked)}
                      name="isPrivate"
                      color="secondary"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>

                  {templateDetail.isPrivate && (
                    <>
                      <div style={{ marginTop: "10px" }}>{t("common_labels_tags")}</div>
                      <div className={classes.gearTextBox}>
                        <TextField
                          id="tag"
                          name="tag"
                          placeholder={t("common_labels_tags_input")}
                          value={tag}
                          onChange={handleChangeTag}
                          fullWidth
                          margin="none"
                          onKeyPress={e => {
                            if (e.key === "Enter" && e.target.value) {
                              addTag();
                            }
                          }}
                        />
                        <Button
                          // size="small"
                          disabled={!tag}
                          onClick={addTag}
                          label={t("common_labels_add")}
                        />
                      </div>

                      {templateDetail &&
                        templateDetail.tags &&
                        templateDetail.tags.length > 0 && (
                          <div className={classes.selectedSection}>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                              justifyContent={"space-between"}
                            >
                              {t("common_labels_selected")} ({templateDetail.tags.length})
                            </Typography>
                            {templateDetail.tags.map((n, index) => (
                              <Chip
                                key={index}
                                label={n}
                                variant="outlined"
                                onDelete={() => handleDeleteTags(n)}
                              />
                            ))}
                          </div>
                        )}

                    </>
                  )}
                </div>
              )}

              {canPostPrivateJob && <div style={{ marginTop: "10px" }}>{t("common_labels_skills")}</div>}
              <div className={classes.gearTextBox}>
                <Autocomplete
                  id="free-solo-skills"
                  freeSolo
                  disableClearable={!skill}
                  onChange={(event, newValue) => {
                    handleChangeSkill(newValue)
                  }}
                  value={skill || ""}
                  fullWidth
                  options={skillList.map((option) => option?.[isFrenchLanguage ? "frenchSkillName" : "skillName"])}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      value={skill}
                      placeholder={t("page_post_job_required_gears_skills_placeholder")}
                      onChange={e => handleChangeSkill(e.target.value)}
                      onKeyPress={e => {
                        if (e.key === "Enter" && e.target.value) {
                          addSkill();
                        }
                      }}
                    />
                  }
                />
                <Button
                  disabled={!skill}
                  onClick={addSkill}
                  label={t("common_labels_add")}
                />
              </div>
              <div className={classes.selectedSection}>
                {templateDetail &&
                  templateDetail.skills &&
                  templateDetail.skills.length > 0 && (
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      {t("common_labels_added_skills")}
                    </Typography>
                  )}
                {templateDetail.skills &&
                  templateDetail.skills.map((n, index) => (
                    <div key={index} className={classes.addedSkillList}>
                      <div className="content">{n?.[isFrenchLanguage ? "frenchSkillName" : "skillName"] || n?.toString()}</div>
                      <IconButton
                        className="closeButton"
                        color="primary"
                        onClick={() => handleDeleteSkill(n)}
                        aria-label="delete"
                        component="span"
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ))}
              </div>

            </Grid>
          </Grid>

          <br />
          <Divider />
          <h3>{t("common_labels_job_location")}</h3>
          <div className={classes.gearTextBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <AddressAutocomplete
                  id="address"
                  name="address"
                  label={t("common_labels_address")}
                  value={address}
                  margin="none"
                  onChange={onAddressChange}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={4}>
                <TextField
                  id="postalCode"
                  name="postalCode"
                  label={`${t("common_labels_postal_code")} *`}
                  value={templateDetail.postalCode}
                  helperText={touchedPostalCodeField && !postalCodeValidator(templateDetail.postalCode || "") ? "Enter Valid Postal Code" : "Required"}
                  onChange={handleChangePostalCode}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  margin="none"
                  error={touchedPostalCodeField && !postalCodeValidator(templateDetail.postalCode || "")}
                  onBlur={handlePostalCodeBlur}
                  onFocus={() => setTouchedPostalCodeField(false)}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <TextField
                  id="unit"
                  name="unit"
                  label={`${t("common_labels_unit")} #`}
                  value={templateDetail.unit}
                  onChange={handleChangeUnit}
                  fullWidth
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={4} sm={4}>
                <TextField
                  id="floor"
                  name="floor"
                  label={`${t("common_labels_floor")} #`}
                  value={templateDetail.floor}
                  onChange={handleChangeFloor}
                  fullWidth
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="locationInstruction"
                  name="locationInstruction"
                  label={`${t("common_labels_additional_instruction")}`}
                  value={templateDetail.locationInstruction}
                  onChange={handleChangeInstruction}
                  fullWidth
                  margin="none"
                />
              </Grid>
            </Grid>
          </div>
          <br />
          <Button
            className={classes.continueButton}
            size="small"
            disabled={!isValidTemplate()}
            isLoading={isLoading}
            onClick={updateTemplate}
            label={t("common_labels_save")}
          />
        </MainContent>
      )}
    </React.Fragment>
  );
}

export default EditJobTemplate;
