import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import * as tzlookup from 'tz-lookup';
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import Button from "components/Button/Button";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { postalCodeValidator } from "utils/stringUtils";

function Location({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const address = useSelector(PostJobSelectors.getAddress)
  const unit = useSelector(PostJobSelectors.getUnit)
  const floor = useSelector(PostJobSelectors.getFloor)
  const postalCode = useSelector(PostJobSelectors.getPostalCode)
  const locationInstruction = useSelector(PostJobSelectors.getLocationInstruction)
  const [touchedPostalCodeField, setTouchedPostalCodeField] = useState(false);

  const { t } = useTranslation();

  const handleChangeUnit = (e) => {
    if (e.target.value.match(/[0-9]/g)) {
      if (e.target.value.length <= 10)
        dispatch(PostJobActions.setUnit(e.target.value));
    }
    else {
      dispatch(PostJobActions.setUnit(''));
    }
  }
  const handleChangeFloor = (e) => {
    if (e.target.value.length <= 10) {
      dispatch(PostJobActions.setFloor(e.target.value));
    }
  }
  const handleChangeInstruction = (e) => {
    if (e.target.value.length <= 100) {
      dispatch(PostJobActions.setLocationInstruction(e.target.value));
    }
  }

  const handleChangePostalCode = (e) => {
    if (e.target.value.length <= 6) {
      dispatch(PostJobActions.setPostalCode(e.target.value.replace?.(" ", "")?.toUpperCase()));
    }
  }


  const onAddressChange = (selectedLocation, location) => {
    if (location) {
      const timezone = tzlookup(location.lat, location.lng);
      dispatch(PostJobActions.setTimezone(timezone));
      dispatch(PostJobActions.setStreet(location.street));
      dispatch(PostJobActions.setCity(location.city));
      dispatch(PostJobActions.setRegion(location.region));
      dispatch(PostJobActions.setLatitude(location.lat));
      dispatch(PostJobActions.setLongitude(location.lng));
      if (location?.postalCode)
        dispatch(PostJobActions.setPostalCode(location.postalCode.replace?.(" ", "")?.toUpperCase()));
      dispatch(PostJobActions.setCountry(location.country));
    }
    dispatch(PostJobActions.setAddress(selectedLocation));
    if (!selectedLocation)
      dispatch(PostJobActions.setPostalCode(''));
  }

  const handlePostalCodeBlur = () => {
    setTouchedPostalCodeField(true);
  };

  return (
    <>
      <h3>{t("common_labels_job_location")}</h3>

      <div className={classes.gearTextBox}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <AddressAutocomplete
              id="address"
              name="address"
              label={t("common_labels_address")}
              value={address}
              margin="none"
              onChange={onAddressChange}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <TextField
              id="postalCode"
              name="postalCode"
              label={`${t("common_labels_postal_code")} *`}
              value={postalCode}
              helperText={touchedPostalCodeField && !postalCodeValidator(postalCode || "") ? "Enter Valid Postal Code" : "Required"}
              onChange={handleChangePostalCode}
              fullWidth
              inputProps={{ maxLength: 50 }}
              margin="none"
              error={touchedPostalCodeField && !postalCodeValidator(postalCode || "")}
              onBlur={handlePostalCodeBlur}
              onFocus={() => setTouchedPostalCodeField(false)}
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <TextField
              id="unit"
              name="unit"
              label={`${t("common_labels_unit")} #`}
              value={unit}
              onChange={handleChangeUnit}
              fullWidth
              inputProps={{ maxLength: 50 }}
              margin="none"
            />
          </Grid>
          <Grid item md={4} xs={12} sm={4}>
            <TextField
              id="floor"
              name="floor"
              label={`${t("common_labels_floor")} #`}
              value={floor}
              onChange={handleChangeFloor}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="locationInstruction"
              name="locationInstruction"
              label={t("common_labels_additional_instruction")}
              value={locationInstruction}
              onChange={handleChangeInstruction}
              fullWidth
              margin="none"
            />
          </Grid>
        </Grid>

      </div>
      <Button
        className={classes.continueButton}
        size="small"
        disabled={!address || address.length === 0 || !postalCode || postalCode.length < 3 || !postalCodeValidator(postalCode || "")}
        onClick={handleNext}
        label={t('common_labels_continue')}
      />
    </>
  );
}

export default Location;
