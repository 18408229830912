import {gql} from "@apollo/client";
export default gql`
query GetTimeBasePremium($employerId: String) {
  getTimeBasePremium(employerId: $employerId) {
    id
    employerId
    startTime
    endTime
    isForFullShift
    type
    value
    days
    createdAt
    updatedAt
    deletedAt
  }
}
`;