import { gql } from "@apollo/client";

export default gql`
mutation AddTimeBasePremium($data: AddPayPremiumByTimeInput!) {
    addTimeBasePremium(data: $data) {
      id
      employerId
      startTime
      endTime
      isForFullShift
      type
      value
      days
      createdAt
      updatedAt
      deletedAt
    }
}
`;