import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Card, CardHeader, Chip, Divider, Grid, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import iconDateTime from "assets/img/iconDateTime.svg";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import { ManagerRoleLabel } from "helpers/constants";
import { find, isEmpty } from "lodash";
import { weekDaysList } from "utils/constant";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, displayLocalDateTime, formattedTimeWithOffset, SplitTime } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { InfoRounded } from "@mui/icons-material";
import FeesDialog from "views/Job/PostJob/components/FeesDialog";
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import moment from "moment";
import i18n from "i18n";

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    backgroundColor: `${palette.common.white}`,
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius / 2,
    padding: spacing(3),
    marginTop: spacing(2),
    paddingLeft: spacing(3),
  },
  totalLine: {
    background: palette.grey[500],
    marginBottom: spacing(2),
    width: "50%",
    height: "1px",
  },
  paymentStatusContainer: {
    paddingTop: spacing(2),
  },
  workType: {
    fontSize: "20px",
    marginTop: "25px",
  },
  location: {
    color: "#202326",
    display: "flex",
    width: "100%",
    "& .icon": {
      width: "30px",
    },
  },
  address: {
    wordBreak: "break-word"
  },
  addressFloor: {
    wordBreak: "break-word",
    width: "100%",
  },
  dateTime: {
    display: "flex",
    "& .icon": {
      width: "30px",
    },
    "& .date": {
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .duration": {
      color: "#848499",
    },
    "& .button": {
      display: "flex",
      marginTop: "10px",
      marginBottom: "10px",
      "& .break": {
        borderRadius: "10px",
        padding: "2px 8px",
        border: "1px solid",
        color: "#00a25b",
        background: "rgb(0 162 91 / 10%)",
        marginRight: "10px",
      },
      "& .recurring": {
        borderRadius: "10px",
        padding: "2px 8px",
        border: "1px solid",
        color: "#0074c8",
        background: "rgb(0 116 200 / 10%)",
      },
    },
  },
  requiredGear: {
    "& .MuiChip-root": {
      marginRight: "8px",
      marginBottom: "8px"
    },
  },
  total: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  jobDescription: {
    wordBreak: "break-word",
  }
}));
export default function JobSummary() {
  const classes = useStyles();
  const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
  const jobEnd = useSelector(JobDetailSelectors.getJobEndUtc);
  const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);
  const isBreakRequiredForJob = useSelector(JobDetailSelectors.getIsBreakRequiredForJob);
  const breakInfo = useSelector(JobDetailSelectors.getJobBreakInfoLabel);
  const jobDescription = useSelector(JobDetailSelectors.getJobDescription);
  const peopleNeeded = useSelector(JobDetailSelectors.getPeopleNeeded);
  const jobTotalDuration = useSelector(JobDetailSelectors.getJobDurationMins);
  const [feesDialog, setFeesDialog] = useState(false);
  const { t } = useTranslation();

  const {
    gears,
    skills,
    jobManager,
    street,
    unit,
    city,
    tags,
    region,
    locationInstruction,
    isRecurring,
    jobRecurring,
    calculateEmployerBillDetail,
    floor,
    isFixedTerm,
    jobSkill,
    jobGear,
  } = useSelector(JobDetailSelectors.getJobDetails);

  const RegularPayHours = (SplitTime(calculateEmployerBillDetail.regularPayHours));
  const OvertimePayHours = (SplitTime(calculateEmployerBillDetail.overtimePayHours));
  const DoublePayHours = (SplitTime(calculateEmployerBillDetail.doublePayHours));
  const timeBasePremiumHours = (SplitTime(calculateEmployerBillDetail.timeBasePremiumHours));

  const duration = Math.round((jobTotalDuration / 60) * 100) / 100;

  const handleClickOpenFeesDialog = () => {
    setFeesDialog(true)
  }

  const handleClickCloseFeesDialog = () => {
    setFeesDialog(false)
  }

  const GearsRendrer = () => {
    const canShowGears = Boolean((jobGear && jobGear.length) || (gears && gears.length));
    return canShowGears && (
      <Grid container item xs={12} sm={6} md={6} direction="column">
        <div className={classes.requiredGear}>
          <br />
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            justifyContent={"space-between"}
          >
            {t("common_labels_required_gears").toUpperCase()}
          </Typography>
          {
            (jobGear && jobGear.length) ?
              jobGear.map(s => (
                <Chip key={s?.id} label={s?.gear?.[i18n.language === "fr" ? "frenchGearName" : "gearName"]} variant="outlined" />
              ))
              : (gears && gears.map((n, index) => <Chip key={index} label={n} variant="outlined" />))
          }
        </div>
      </Grid>
    )
  }

  const SkillsRendrer = () => {
    const canShowSkills = Boolean((jobSkill && jobSkill.length) || (skills && skills.length));
    return (canShowSkills && (
      <Grid container item xs={12} sm={6} md={6} direction="column">
        <div className={classes.requiredGear}>
          <br />
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            justifyContent={"space-between"}
          >
            {t("common_labels_suggested_skills").toUpperCase()} {tags && tags.length > 0 && t("common_labels_suggested_skills_optional").toUpperCase()}
          </Typography>
          <ul>
            {
              (jobSkill && jobSkill.length) ?
                jobSkill.map(s => (
                  <li key={s?.id}>{s?.skill?.[i18n.language === "fr" ? "frenchSkillName" : "skillName"]}</li>
                ))
                : (skills && skills.map((n, index) => <li key={index}>{n}</li>))
            }
          </ul>
          <div style={{ paddingLeft: "20px" }}>
            {tags && tags.map((n, index) => (
              <Chip key={index} label={n} variant="outlined" />
            ))}
          </div>
        </div>
      </Grid>
    ))
  }

  return (
    <>
      <Grid container item xs={12} className={classes.root}>
        <Grid container item xs={12} sm={6} md={6}>
          <div className={classes.dateTime}>
            <div className="icon">
              <img src={iconDateTime} />
            </div>
            <div className="detail">
              <div className="date">
                {`${convertUTCToTimezoneWithOffset(jobStart, jobTimezone, "YYYY/MM/DD", true)} - ${moment(jobRecurring?.frequencyEndAt).format("YYYY/MM/DD")} ${convertUTCToTimezoneWithOffset(jobStart, jobTimezone, " ")} ${displayLocalDateTime(jobStart, jobTimezone, "YYYY/MM/DD", moment(jobRecurring?.frequencyEndAt).format("YYYY/MM/DD"))}`}
                {/* {convertUTCToTimezoneWithOffset(jobStart, jobTimezone, "dddd, MMMM Do, YYYY")} */}
              </div>
              <div className="time">{formattedTimeWithOffset(jobStart, jobEnd, jobTimezone)}</div>
              <div className="duration">({t("common_labels_job_hours_duration", { duration })})</div>
              <div className="button">
                {isBreakRequiredForJob && <div className="break">{t(breakInfo.key, { breakDuration: breakInfo.breakDuration })}</div>}
                <div className="recurring">{isRecurring === false ? t("common_labels_onTime") : isFixedTerm ? t("common_labels_fixed_term_job") : t("common_labels_recurring")}</div>
              </div >
              {isRecurring && jobRecurring && (
                <React.Fragment>
                  {jobRecurring.frequency === "Daily" && (
                    <div className="time">{t("common_labels_repeat_every_day", { day: jobRecurring.frequencyInterval })} </div>
                  )}
                  {jobRecurring.frequency === "Weekly" && (
                    <div className="time">
                      {t("common_labels_repeat_every_week", {
                        week: jobRecurring.frequencyInterval > 1
                          ? jobRecurring.frequencyInterval + " "
                          : ""
                      })}
                      , &nbsp;
                      <b>{t("common_labels_days")}:</b> &nbsp;
                      {jobRecurring.dayOrWeekDay.map((m, ind) => {
                        const day = find(weekDaysList, { value: m });
                        if (day) {
                          return <span key={ind}>{(ind != 0 ? ", " : "") + day.label}</span>;
                        }
                      })}
                    </div>
                  )}
                  {jobRecurring.frequency === "Monthly" && (
                    <div className="time">
                      {t("common_labels_repeat_every_month", {
                        month: jobRecurring.frequencyInterval > 1
                          ? jobRecurring.frequencyInterval + " "
                          : ""
                      })}
                      , &nbsp;
                      <b>{t("common_labels_date")}:</b> &nbsp;
                      {jobRecurring.dayOrWeekDay.map((m, ind) => (
                        <span key={ind}>{(ind != 0 ? ", " : "") + m}</span>
                      ))}
                    </div>
                  )}
                </React.Fragment >
              )
              }
            </div >
          </div >
        </Grid >
        <Grid container item xs={12} sm={6} md={6} direction="column">
          <div className={classes.location}>
            <div className="icon">
              <img src={iconPinLocation} />
            </div>
            <div className={classes.address}>{`${unit || ""} ${street || ""} ${city || ""} ${region || ""}`}</div>
          </div>
          <br />
          {/* {unit && (
            <div>
              <b>Unit: </b> {unit}{" "}
            </div>
          )} */}
          {floor && (
            <div className={classes.addressFloor}>
              <b>{t("common_labels_floor")}: </b> {floor}{" "}
            </div>
          )}
          {locationInstruction && (
            <div className={classes.addressFloor}>
              <b>{t("common_labels_additional_instruction")}:</b> {locationInstruction}
            </div>
          )}
        </Grid>
        <Grid container item xs={12}>
          <GearsRendrer />
          <SkillsRendrer />
        </Grid>
        <Divider />
        <Grid container item xs={12} sm={12} md={12} direction="column">
          <div className={classes.requiredGear}>
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              {t("common_labels_description")}
            </Typography>
            {jobDescription && (
              <ul>
                <li className={classes.jobDescription}>{jobDescription}</li>
              </ul>
            )}
          </div>
        </Grid>
        <Divider />
        {
          jobManager &&
          jobManager.length > 0 && (
            <Grid container item xs={12} sm={12} md={12} direction="column">
              <div className={classes.requiredGear}>
                <br />
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  {t("common_labels_project_role").toUpperCase()}
                </Typography>
                <Grid container spacing={2} justifyContent={"start"}>
                  {jobManager.map(n => (
                    <Grid key={n.id} item md={4} sm={6} xs={12}>
                      <Card>
                        <CardHeader
                          avatar={<Avatar alt={n.manager?.firstName} src={n.manager?.signedProfileImageUrl} />}
                          title={
                            !isEmpty(n) &&
                            n.manager &&
                            `${n.manager.firstName} ${n.manager.lastName}`
                          }
                          subheader={t("common_dynamic_managers_" + ManagerRoleLabel[n.role])}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          )
        }
        <Grid container item xs={12} sm={12} md={12}>

          <Grid item md={12} sm={12} xs={12} justifyContent={"start"}>
            <br />
            <Divider />
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              {t("common_labels_budget")}
            </Typography>

            <Grid container xs={12}>
              <Grid item xs={6}>
                {t("common_labels_hourlyPay", { hours: RegularPayHours.Hours, minutes: RegularPayHours.Minutes, workers: peopleNeeded })}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.hourlyPay)}
              </Grid>
            </Grid>
            {calculateEmployerBillDetail.overtimePay > 0 && (
              <Grid container xs={12}>
                <Grid item xs={6}>
                  {t("common_labels_otPay", { hours: OvertimePayHours.Hours, minutes: OvertimePayHours.Minutes, workers: peopleNeeded })}
                  <Tooltip title={t("common_labels_overtime_text")}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(calculateEmployerBillDetail.overtimePay)}
                </Grid>
              </Grid>
            )}
            {calculateEmployerBillDetail.doublePay > 0 && (
              <Grid container xs={12}>
                <Grid item xs={6}>
                  {t("common_labels_otPay", { hours: DoublePayHours.Hours, minutes: DoublePayHours.Minutes, workers: peopleNeeded })}
                  <Tooltip title={t("common_labels_doubletime_text")}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(calculateEmployerBillDetail.doublePay)}
                </Grid>
              </Grid>
            )}
            {calculateEmployerBillDetail.timeBasePremium > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  {t("common_labels_tbpPay", { hours: timeBasePremiumHours.Hours, minutes: timeBasePremiumHours.Minutes, workers: peopleNeeded })}
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculateEmployerBillDetail.timeBasePremium)}
                </Grid>
              </Grid>
            )}
            <Grid container xs={12}>
              <Grid item xs={6}>
                {t("common_labels_gf_fees")}
                <IconButton
                  style={{ padding: "5px", marginTop: "-3px" }}
                  onClick={handleClickOpenFeesDialog}
                >
                  <InfoRounded style={{ fontSize: "14px" }} />
                </IconButton>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.gfFee)}
              </Grid>
            </Grid>
            {calculateEmployerBillDetail?.taxes?.map(tx => (
              <Grid key={tx.label} container xs={12}>
                <Grid item xs={6}>
                  {`${tx.label} ${tx.percentage}%`}
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(tx.amount)}
                </Grid>
              </Grid>
            ))}
            <Divider />
            <br />
            <Grid
              container
              className={classes.total}
              xs={12}
            >
              <Grid item xs={6}>
                {t("common_labels_totalDue").toUpperCase()}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.total)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
      <Divider />
      <FeesDialog
        feesDialog={feesDialog}
        handleClickCloseFeesDialog={handleClickCloseFeesDialog}
      />
    </>
  );
}
